<template>
  <div class="content-body pb-8">
    <div class="space-y-7">
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold">Leave Approval</h1>
      </div>
      <div class="shadow-small card pt-4 pb-8 bg-white rounded-lg px-6">
        <div class="grid grid-cols-6 justify-start gap-3 mb-3 w-full">
          <div class="">
            <TextField type="date" label="Start Date" v-model="startDate" :max="endDate" borderEnabled placeholder="Select Date" />
          </div>
          <div class="">
            <TextField type="date" :disabled="!startDate" :min="startDate" label="End Date" v-model="endDate" borderEnabled placeholder="Select Date" />
          </div>
          <div>
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Type</label>
            <Dropdown :options="leaveTypeOptions" :default="leaveTypeSelected" optionLabel="name" v-model="leaveTypeSelected" placeholder="All" :isReset="isResetDropdown" />
          </div>
          <div>
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Status</label>
            <Dropdown :options="statusOptions" :default="statusSelected" optionLabel="name" v-model="statusSelected" placeholder="All" :isReset="isResetDropdown" />
          </div>
          <div class="flex gap-3 col-span-2 pt-5">
            <div>
              <Button buttonText="Apply Filter" type="primary" @action="onSearch()" additionalClass="h-11" />
            </div>
            <div>
              <Button buttonText="Reset" type="tertiary" additionalClass="h-11" @action="clearParameter()" v-if="true" />
            </div>
          </div>
        </div>
        <div class="flex-1 flex justify-start items-end">
          <div class="flex gap-2 items-end">
            <div class="w-80">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3">Search by Request No or Name</label>
              <div class="relative">
                <div class="absolute left-4 top-1/2 transform -translate-y-1/2">
                  <Search color="neutral-400" />
                </div>
                <TextField type="text" withIcon borderEnabled placeholder="Enter request no or name" :enterKeyAction="onSearch" v-model="keyword" />
              </div>
            </div>
            <div class="flex gap-5">
              <Button buttonText="Search" type="secondary" size="regular" additionalClass="px-6 py-3" @action="onSearch()" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="stillLoading" class="loading-page">
        <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
      </div>
      <div v-if="leaveRequestList.length > 0 && !stillLoading">
        <LeaveTable :columns="columnsTable" :list="leaveRequestList" compact>
          <template slot="table-row" slot-scope="{ entry, column }">
            <div v-if="column.label === 'Actions'" class="flex justify-end gap-3">
              <Button
                type="tertiary"
                size="icon"
                :forIcon="{ color: 'yellow' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Eye')"
                :tooltip="{ show: true, text: 'Detail' }"
                @action="toDetailPage(entry.leaveNo)"
              />
              <Button
                :disabled="entry.status !== 'On Review'"
                type="greenTertiary"
                size="icon"
                :forIcon="{ color: entry.status === 'On Review' ? 'green' : '' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/CheckmarkCircle')"
                :tooltip="{ show: true, text: 'Approve' }"
                @action="toggleConfirmation({ status: 'Approve', leaveRequestId: entry.id })"
              />
              <Button
                :disabled="entry.status !== 'On Review' && entry.status !== 'Approved'"
                type="deleteTertiary"
                size="icon"
                :forIcon="{ color: entry.status === 'On Review' || entry.status === 'Approved' ? 'red' : '', width: '23', height: '23' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/CrossCircle')"
                :tooltip="{ show: true, text: 'Reject' }"
                @action="toggleConfirmation({ status: 'Reject', leaveRequestId: entry.id })"
              />
            </div>
            <div v-else-if="column.label === 'Duration'">{{ entry[column.field] }} {{ entry.leaveTypeUnit.toLowerCase() }}</div>
            <div v-else-if="column.label === 'Status'" :class="[statusStyle(entry.status)]" class="p-2 text-center text-xs font-bold rounded-lg">{{ entry[column.field] }}</div>
            <div v-else-if="column.label === 'Start Time'">{{ convertDate(entry.startDate, entry.leaveTypeUnit) }}</div>
            <div v-else-if="column.label === 'End Time'">{{ convertDate(entry.endDate, entry.leaveTypeUnit) }}</div>
            <div v-else-if="column.label === 'Name' || column.label === 'Department'" class="min-w-max">{{ entry[column.field] }}</div>
            <div v-else>{{ entry[column.field] }}</div>
          </template>
        </LeaveTable>
        <div v-if="totalLeaveRequest > sizePerPage" class="mt-3 flex justify-center items-center">
          <Pagination :currentPage="currentPage" :totalCount="totalLeaveRequest" :pageSize="sizePerPage" @onPageChange="onPageChange" />
        </div>
      </div>
      <div class="text-center mt-10" v-else>
        <img src="@/assets/images/empty-content.svg" alt="empty" class="mx-auto" />
        <div class="font-medium text-xl py-5">Data is not available</div>
      </div>
    </div>
    <Modal :modalVisible="confirmationAlertVisible" @close="toggleConfirmation()">
      <template slot="modal-content">
        <div class="my-2 text-left">
          <h1 class="text-xl font-bold mb-3">{{ leaveRequestSelected?.status }} Leave Request?</h1>

          <p class="text-sm mb-4" v-if="leaveRequestSelected.status === 'Approve'">
            {{ leaveRequestSelected.status === 'Approve' ? 'Approving' : 'Rejecting' }} Leave Request will {{ leaveRequestSelected.status === 'Approve' ? '' : 'Not' }} affect related Employee’s Leave
            Balance.
          </p>
          <TextArea label="Notes" class="mb-4 mx-1" isLabelRequire height="h-28" borderEnabled v-model="notesFromAdmin" v-else />
          <div class="flex justify-end items-center gap-4">
            <Button buttonText="Cancel" type="tertiary" @action="toggleConfirmation()" />
            <Button buttonText="Confirm" type="primary" :disabled="!notesFromAdmin" @action="confirmAction()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    TextArea: () => import(/* webpackChunkName: "TextArea" */ '@/components/Form/TextArea'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Search'),
    LeaveTable: () => import(/* webpackChunkName: "simple-table" */ '@/components/Table/SimpleTable'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  data() {
    return {
      currentPage: 1,
      sizePerPage: 10,
      confirmationAlertVisible: false,
      stillLoading: false,
      keyword: '',
      startDate: '',
      endDate: '',
      leaveTypeSelected: {
        id: '',
        name: 'All'
      },
      statusSelected: {
        id: '',
        name: 'All'
      },
      debounce: null,
      delayTime: 500,
      leaveTypeDeleted: null,
      isResetDropdown: false,
      leaveRequestListDummy: [
        {
          id: 61,
          leaveNo: '2023070031',
          employeeName: 'Nuryanto',
          createdDate: '2023-07-24T04:00:33.877+00:00',
          status: 'ON_REVIEW',
          department: 'HRD',
          leaveType: 'Annual Leave',
          leaveTypeId: 7,
          leaveTypeUnit: 'DAYS',
          leaveTypeDurationType: 'LIMITED',
          startDate: '2023-07-21T16:00:00.000+00:00',
          endDate: '2023-07-21T16:59:00.000+00:00',
          leaveDuration: 3,
          reason: 'test time url',
          approvalUrl: 'tes'
        },
        {
          id: 60,
          leaveNo: '2023070030',
          employeeName: 'Taufix',
          createdDate: '2023-07-24T03:58:53.312+00:00',
          status: 'ON_REVIEW',
          department: 'HRD',
          leaveType: 'Annual Leave',
          leaveTypeId: 7,
          leaveTypeUnit: 'DAYS',
          leaveTypeDurationType: 'LIMITED',
          startDate: '2023-07-21T15:00:00.000+00:00',
          endDate: '2023-07-21T15:59:00.000+00:00',
          leaveDuration: 2,
          reason: 'test time url',
          approvalUrl: 'tes'
        }
      ],
      leaveTypeOptions: [
        {
          id: '',
          name: 'All'
        }
      ],
      statusOptions: [
        {
          name: 'All',
          id: ''
        },
        {
          name: 'On Review',
          id: 'ON_REVIEW'
        },
        {
          name: 'Approved',
          id: 'APPROVED'
        },
        {
          name: 'Rejected',
          id: 'REJECTED'
        },
        {
          name: 'Recalled',
          id: 'CANCELLED'
        }
      ],
      leaveRequestSelected: {
        status: '',
        leaveRequestId: ''
      },
      notesFromAdmin: ''
    }
  },
  computed: {
    ...mapGetters('humanresource', ['leaveTypeList', 'totalLeaveRequest', 'leaveRequestList']),
    isClearBtnActive() {
      if (this.endDate !== '' || this.startDate !== '' || this.keyword !== '') {
        return true
      }
      return false
    },
    customerId() {
      return localStorage.getItem('client')
    },
    columnsTable() {
      return [
        { label: 'Request No', field: 'leaveNo' },
        { label: 'Name', field: 'employeeName' },
        { label: 'Department', field: 'department' },
        { label: 'Leave Type', field: 'leaveType' },
        { label: 'Start Time', field: 'startDate' },
        { label: 'End Time', field: 'endDate' },
        { label: 'Duration', field: 'leaveDuration' },
        { label: 'Status', field: 'status' },
        { label: 'Actions', field: 'btn', position: 'right' }
      ]
    }
  },
  created() {
    this.fetchingData()
    this.getLeaveTypeOptions()
  },
  methods: {
    ...mapActions('humanresource', ['GET_LEAVE_REQUEST_LIST', 'GET_LEAVE_TYPE_LIST', 'RESPONSE_LEAVE_REQUEST']),
    toDetailPage(leaveRequetId) {
      this.$router.push(`/leave-approval/${leaveRequetId}`)
    },
    utcToLocal(day, format) {
      const localTime = dayjs.utc(day).local().format(format)
      return localTime
    },
    convertDate(date, type) {
      if (type === 'DAYS') return dayjs(date).format('DD MMM YYYY')
      const format = 'DD MMM YYYY HH:mm'
      return this.utcToLocal(date, format)
    },
    getLeaveTypeOptions() {
      const params = {
        q: this.keyword,
        customerId: this.customerId,
        direction: 'DESC',
        limit: 500,
        page: 0,
        sortBy: 'updatedDate'
      }

      this.GET_LEAVE_TYPE_LIST({ params }).then((res) => {
        if (res?.code === 200) {
          res?.data?.map((item) => {
            this.leaveTypeOptions.push({
              id: item.id,
              name: item.name
            })
          })
        }
      })
    },
    statusStyle(status) {
      let style = ''
      switch (status) {
        case 'Approved':
          style = 'text-green-100 bg-green-10'
          break
        case 'On Review':
          style = 'text-blue-100 bg-blue-10'
          break
        default:
          style = 'text-red-100 bg-red-10'
          break
      }
      return style
    },
    fetchingData() {
      this.stillLoading = true
      let params = {
        q: this.keyword,
        customerId: this.customerId,
        direction: 'DESC',
        limit: this.sizePerPage,
        page: this.currentPage - 1,
        sortBy: 'updatedDate',
        startDate: this.startDate,
        endDate: this.endDate,
        status: this.statusSelected.id,
        leaveTypeId: this.leaveTypeSelected.id
      }

      this.GET_LEAVE_REQUEST_LIST({ params }).then(() => {
        this.stillLoading = false
      })
    },
    onPageChange(page) {
      this.currentPage = page
      this.fetchingData()
    },
    clearParameter() {
      this.keyword = ''
      this.currentPage = 1
      this.startDate = ''
      this.endDate = ''
      this.leaveTypeSelected = this.leaveTypeOptions[0]
      this.statusSelected = this.statusOptions[0]
      this.isResetDropdown = !this.isResetDropdown
      this.fetchingData()
    },
    onSearch() {
      this.currentPage = 1
      if (this.stillLoading) return
      this.fetchingData()
    },
    confirmAction() {
      const payload = {
        status: this.leaveRequestSelected.status === 'Approve' ? 'APPROVED' : 'REJECTED',
        note: this.notesFromAdmin
      }
      this.RESPONSE_LEAVE_REQUEST({ payload, leaveRequestId: this.leaveRequestSelected.leaveRequestId })
        .then((res) => {
          if (res?.data?.code === 200) {
            showVueToast('Success', 'success', 2000)
            this.toggleConfirmation()
            this.clearParameter()
          } else {
            showVueToast('Your request is failed!', 'error', 2000)
          }
        })
        .catch(() => {
          showVueToast('Your request is failed!', 'error', 2000)
        })
    },
    toggleConfirmation(leaveRequestSelected) {
      if (leaveRequestSelected) {
        this.leaveRequestSelected = leaveRequestSelected
        this.notesFromAdmin = leaveRequestSelected.status === 'Approve' ? 'Approve from admin' : ''
      }
      this.confirmationAlertVisible = !this.confirmationAlertVisible
    }
  }
}
</script>

<style lang="scss" scoped>
.content-body {
  min-height: calc(83vh - 0px);
}
</style>