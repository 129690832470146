var render = function () {
  var _vm$leaveRequestSelec;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-body pb-8"
  }, [_c('div', {
    staticClass: "space-y-7"
  }, [_vm._m(0), _c('div', {
    staticClass: "shadow-small card pt-4 pb-8 bg-white rounded-lg px-6"
  }, [_c('div', {
    staticClass: "grid grid-cols-6 justify-start gap-3 mb-3 w-full"
  }, [_c('div', {}, [_c('TextField', {
    attrs: {
      "type": "date",
      "label": "Start Date",
      "max": _vm.endDate,
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1), _c('div', {}, [_c('TextField', {
    attrs: {
      "type": "date",
      "disabled": !_vm.startDate,
      "min": _vm.startDate,
      "label": "End Date",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Type")]), _c('Dropdown', {
    attrs: {
      "options": _vm.leaveTypeOptions,
      "default": _vm.leaveTypeSelected,
      "optionLabel": "name",
      "placeholder": "All",
      "isReset": _vm.isResetDropdown
    },
    model: {
      value: _vm.leaveTypeSelected,
      callback: function callback($$v) {
        _vm.leaveTypeSelected = $$v;
      },
      expression: "leaveTypeSelected"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Status")]), _c('Dropdown', {
    attrs: {
      "options": _vm.statusOptions,
      "default": _vm.statusSelected,
      "optionLabel": "name",
      "placeholder": "All",
      "isReset": _vm.isResetDropdown
    },
    model: {
      value: _vm.statusSelected,
      callback: function callback($$v) {
        _vm.statusSelected = $$v;
      },
      expression: "statusSelected"
    }
  })], 1), _c('div', {
    staticClass: "flex gap-3 col-span-2 pt-5"
  }, [_c('div', [_c('Button', {
    attrs: {
      "buttonText": "Apply Filter",
      "type": "primary",
      "additionalClass": "h-11"
    },
    on: {
      "action": function action($event) {
        return _vm.onSearch();
      }
    }
  })], 1), _c('div', [true ? _c('Button', {
    attrs: {
      "buttonText": "Reset",
      "type": "tertiary",
      "additionalClass": "h-11"
    },
    on: {
      "action": function action($event) {
        return _vm.clearParameter();
      }
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "flex-1 flex justify-start items-end"
  }, [_c('div', {
    staticClass: "flex gap-2 items-end"
  }, [_c('div', {
    staticClass: "w-80"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3"
  }, [_vm._v("Search by Request No or Name")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "absolute left-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "neutral-400"
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Enter request no or name",
      "enterKeyAction": _vm.onSearch
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1)]), _c('div', {
    staticClass: "flex gap-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "type": "secondary",
      "size": "regular",
      "additionalClass": "px-6 py-3"
    },
    on: {
      "action": function action($event) {
        return _vm.onSearch();
      }
    }
  })], 1)])])]), _vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _vm._e(), _vm.leaveRequestList.length > 0 && !_vm.stillLoading ? _c('div', [_c('LeaveTable', {
    attrs: {
      "columns": _vm.columnsTable,
      "list": _vm.leaveRequestList,
      "compact": ""
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.label === 'Actions' ? _c('div', {
          staticClass: "flex justify-end gap-3"
        }, [_c('Button', {
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "forIcon": {
              color: 'yellow'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Eye');
            },
            "tooltip": {
              show: true,
              text: 'Detail'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toDetailPage(entry.leaveNo);
            }
          }
        }), _c('Button', {
          attrs: {
            "disabled": entry.status !== 'On Review',
            "type": "greenTertiary",
            "size": "icon",
            "forIcon": {
              color: entry.status === 'On Review' ? 'green' : ''
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/CheckmarkCircle');
            },
            "tooltip": {
              show: true,
              text: 'Approve'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toggleConfirmation({
                status: 'Approve',
                leaveRequestId: entry.id
              });
            }
          }
        }), _c('Button', {
          attrs: {
            "disabled": entry.status !== 'On Review' && entry.status !== 'Approved',
            "type": "deleteTertiary",
            "size": "icon",
            "forIcon": {
              color: entry.status === 'On Review' || entry.status === 'Approved' ? 'red' : '',
              width: '23',
              height: '23'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/CrossCircle');
            },
            "tooltip": {
              show: true,
              text: 'Reject'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toggleConfirmation({
                status: 'Reject',
                leaveRequestId: entry.id
              });
            }
          }
        })], 1) : column.label === 'Duration' ? _c('div', [_vm._v(_vm._s(entry[column.field]) + " " + _vm._s(entry.leaveTypeUnit.toLowerCase()))]) : column.label === 'Status' ? _c('div', {
          staticClass: "p-2 text-center text-xs font-bold rounded-lg",
          class: [_vm.statusStyle(entry.status)]
        }, [_vm._v(_vm._s(entry[column.field]))]) : column.label === 'Start Time' ? _c('div', [_vm._v(_vm._s(_vm.convertDate(entry.startDate, entry.leaveTypeUnit)))]) : column.label === 'End Time' ? _c('div', [_vm._v(_vm._s(_vm.convertDate(entry.endDate, entry.leaveTypeUnit)))]) : column.label === 'Name' || column.label === 'Department' ? _c('div', {
          staticClass: "min-w-max"
        }, [_vm._v(_vm._s(entry[column.field]))]) : _c('div', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }], null, false, 3421739405)
  }), _vm.totalLeaveRequest > _vm.sizePerPage ? _c('div', {
    staticClass: "mt-3 flex justify-center items-center"
  }, [_c('Pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalLeaveRequest,
      "pageSize": _vm.sizePerPage
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1) : _c('div', {
    staticClass: "text-center mt-10"
  }, [_c('img', {
    staticClass: "mx-auto",
    attrs: {
      "src": require("@/assets/images/empty-content.svg"),
      "alt": "empty"
    }
  }), _c('div', {
    staticClass: "font-medium text-xl py-5"
  }, [_vm._v("Data is not available")])])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.confirmationAlertVisible
    },
    on: {
      "close": function close($event) {
        return _vm.toggleConfirmation();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "my-2 text-left"
  }, [_c('h1', {
    staticClass: "text-xl font-bold mb-3"
  }, [_vm._v(_vm._s((_vm$leaveRequestSelec = _vm.leaveRequestSelected) === null || _vm$leaveRequestSelec === void 0 ? void 0 : _vm$leaveRequestSelec.status) + " Leave Request?")]), _vm.leaveRequestSelected.status === 'Approve' ? _c('p', {
    staticClass: "text-sm mb-4"
  }, [_vm._v(" " + _vm._s(_vm.leaveRequestSelected.status === 'Approve' ? 'Approving' : 'Rejecting') + " Leave Request will " + _vm._s(_vm.leaveRequestSelected.status === 'Approve' ? '' : 'Not') + " affect related Employee’s Leave Balance. ")]) : _c('TextArea', {
    staticClass: "mb-4 mx-1",
    attrs: {
      "label": "Notes",
      "isLabelRequire": "",
      "height": "h-28",
      "borderEnabled": ""
    },
    model: {
      value: _vm.notesFromAdmin,
      callback: function callback($$v) {
        _vm.notesFromAdmin = $$v;
      },
      expression: "notesFromAdmin"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex justify-end items-center gap-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleConfirmation();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Confirm",
      "type": "primary",
      "disabled": !_vm.notesFromAdmin
    },
    on: {
      "action": function action($event) {
        return _vm.confirmAction();
      }
    }
  })], 1)], 1)])], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-between items-center mb-6"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Leave Approval")])]);
}]

export { render, staticRenderFns }